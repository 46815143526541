/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track:hover {
  background-color: #e4e4e4;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 5px;
  border: 2px solid #e4e4e4;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 1px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

.page-content {
  &:-webkit-scrollbar-track {
    background-color: #e8e8e8;
  }
}

.mat-card,
.ag-body-viewport,
.mat-autocomplete-panel {
  &:-webkit-scrollbar-track {
    background-color: #ffffff;
  }

  &:-webkit-scrollbar-thumb {
    border: 2px solid #ffffff;
  }
}
