.md-chat-widget-btn-container {
  visibility: hidden;
}

.md-chat-widget-container {
  top: 46px;
  right: 102px !important;
  left: initial !important;
  bottom: initial !important;
}
