mat-horizontal-stepper {
  --mat-stepper-container-color: transparent;
  --mat-stepper-content-padding: 12px;

  display: flex;
  flex-direction: column;
  background-color: transparent;

  .mat-horizontal-stepper-wrapper {
    height: 100%;
  }

  .mat-horizontal-stepper-header-container {
    background-color: #fff;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0), 0 2px 2px 0 rgba(0, 0, 0, 0),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
    z-index: 10;

    .mat-step-label {
      font-weight: 500;
    }
  }

  .mat-horizontal-content-container {
    flex: 1 1 auto;
    padding: 0;
    overflow: hidden;

    .mat-horizontal-stepper-content {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      overflow: auto;

      &:not(.mat-horizontal-stepper-content-inactive) {
        height: 100%;
        min-height: 100%;
        padding: var(--mat-stepper-content-padding);
      }
    }
  }
}
