// TODO - mover para o combobox
/* Altera o comprimento do label ao subir o input */
/* Com isso, passa a ser possivel um label encima do icone do combo */
astutus-combobox {

    mat-form-field.mat-form-field-should-float {
        
        span.mat-form-field-label-wrapper {
            width: calc(100% + 21px) !important;
        }

    }

}
