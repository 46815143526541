//
// Coleção de displays que podem ser utilizados de forma direta em cada elemento html.
//

.display-none {
  display: none !important;
}

.display-inline {
  display: inline !important;
}

.display-inline-block {
  display: inline-block !important;
}

.display-block {
  display: block !important;
}

.display-table {
  display: table !important;
}

.display-table-row {
  display: table-row !important;
}

.display-table-cell {
  display: table-cell !important;
}

.display-flex {
  display: flex !important;
}

.display-inline-flex {
  display: inline-flex !important;
}

