@mixin calc-grid-flex-size($index) {
  @if ($index > 0) {
    .flex-#{$index} {
      width: percentage(($index / $grid-columns)) !important;
      flex: 0 0 percentage(($index / $grid-columns)) !important;
    }
  }
}

@mixin loop-flex-size($columns) {
  @for $i from 0 through $columns {
    @include calc-grid-flex-size($i);
  }
}

@mixin make-flex-grid($grid-columns) {
  @include loop-flex-size($grid-columns);
}