// linhas dos ícones
.hamburger .line{
  width: 12px;
  height: 2px;
  background-color: #ecf0f1;
  margin-bottom: 3px;
  display: block;
  -webkit-transition: all 0.07s ease-in-out;
  -o-transition: all 0.07s ease-in-out;
  transition: all 0.07s ease-in-out;

  &:last-child {
    margin-bottom: 0;
  }
}

// Estilo dos botões hamburger
.hamburger-option {
  &.mat-fab {
    background-color: transparent;
    box-shadow: none !important;
    color: #FFF;
    width: 48px;
    height: 48px;
    margin-right: 9px;
    margin-left: 9px;

    &:hover{
      cursor: pointer;
      background-color: $hamburger-hover;
    }
  }

  &.hamburger-button {
    .mat-button-wrapper {
      padding: 0
    }
  }
}

// Tamanho das linhas do hamburger quando ativado (primeira e última)
#hamburger-3.is-active .line:nth-child(1),
#hamburger-3.is-active .line:nth-child(3){
  width: 8px;
}

#hamburger-3.is-active .line {
  margin-bottom: 1px;
}

// Rotação para formar uma seta
#hamburger-3.is-active .line:nth-child(1){
  -webkit-transform: translateX(-0px) rotate(-45deg);
  -ms-transform: translateX(0px) rotate(-45deg);
  -o-transform: translateX(0px) rotate(-45deg);
  transform: translateX(0px) rotate(-45deg);
}

#hamburger-3.is-active .line:nth-child(3){
  -webkit-transform: translateX(0px) rotate(45deg);
  -ms-transform: translateX(0px) rotate(45deg);
  -o-transform: translateX(0px) rotate(45deg);
  transform: translateX(0px) rotate(45deg);
}