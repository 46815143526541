//Grid usando flexbox
@import '../abstracts/mixins';

[row-inline],
.row-inline {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-left: $element-margin * -1;
  margin-right: $element-margin * -1;
}

[row-wrap],
.row-wrap {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-left: $element-margin * -1;
  margin-right: $element-margin * -1;
  flex-wrap: wrap;
}

[layout-flex] {
  flex: 1;
  margin-right: $element-margin;
}

[layout-flex-with-margin] {
  flex: 1;
  margin-right: $element-margin;
  margin-left: $element-margin;
}

[hidden] {
  display: none !important;
}

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 calc(#{$size * (100% / $grid-columns)} - #{$element-margin * 2}) !important;
  width: calc(#{$size * (100% / $grid-columns)} - #{$element-margin * 2}) !important;
}

@for $i from 1 through $grid-columns {
  [col-#{$i}],
  .col-#{$i} {
    margin-right: $element-margin;
    margin-left: $element-margin;
    width: calc(#{$i * (100% / $grid-columns)} - #{$element-margin * 2}) !important;
  }

  @media screen and (max-width: 575px) {
    [col-xs-#{$i}],
    .col-xs-#{$i} {
      margin-right: $element-margin;
      margin-left: $element-margin;
      @include make-col($i, $grid-columns);
    }
  }

  @media screen and (min-width: 576px) and (max-width: 719px) {
    [col-sm-#{$i}],
    .col-sm-#{$i} {
      margin-right: $element-margin;
      margin-left: $element-margin;
      @include make-col($i, $grid-columns);
    }
  }

  @media screen and (min-width: 720px) and (max-width: 991px) {
    [col-md-#{$i}],
    .col-md-#{$i} {
      margin-right: $element-margin;
      margin-left: $element-margin;
      @include make-col($i, $grid-columns);
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    [col-lg-#{$i}],
    .col-lg-#{$i} {
      margin-right: $element-margin;
      margin-left: $element-margin;
      @include make-col($i, $grid-columns);
    }
  }

  @media screen and (min-width: 1200px) {
    [col-xl-#{$i}],
    .col-xl-#{$i} {
      margin-right: $element-margin;
      margin-left: $element-margin;
      @include make-col($i, $grid-columns);
    }
  }
}

@for $i from 1 through $grid-columns {
  .col-grid-#{$i} {
  }
}

@include make-flex-grid($grid-columns);
