mat-checkbox.mat-checkbox-checked {
  label {
    color: rgba(0, 0, 0, 0.87);
  }
}

mat-checkbox:not(.mat-checkbox-checked) {
  label {
    color: rgba(0, 0, 0, 0.54);
  }
}