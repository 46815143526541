// Mixin utilizado para possibilitar a utilização das configurações do tema no componente card.
@use '@angular/material' as mat;
@mixin card-theme($theme) {

  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  .card {
    &.card-primary {
      background-color: mat.get-color-from-palette($primary);
      color: white;
    }
    &.card-accent {
      background-color: mat.get-color-from-palette($primary);
      color: white;
    }
    &.card-white {
      background-color: white;
      color: mat.get-color-from-palette($primary);
    }
  }
}
