// Container utilizado para que elementos filhos herdem sua altura de forma dinâmica
// preenchendo completamente o espaço. Ao marcar um elemento com fill-fill-height-container ele deverá
// ter logo em seguida elementos marcados com fill-height-content, esses elementos
// podem receber as marcações de colunas (col-2, col-3, col-4, etc...) para definir
// seus respectivos tamanhos. A class fill-heigth incorpora o comportamento do row-inline,
// dessa forma não é necessário reaplicar esses estilos para utilizar as colunas
/**
 * @example
 *
 *   <div class="fill-fill-height-container">
 *     <div class="fill-height-content" col-8>
 *       <span>Coluna de tamanho 8</span>
 *     </div>
 *     <div class="fill-height-content" col-4>
 *       <span>Coluna de tamanho 4</span>
 *     </div>
 *   </div>
 */
.fill-height-container {
  margin: 0 -8px;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;

  > .fill-height-content {
    display: flex;
    min-height: 100%;
  }
}

.fill-height-content > * {
  width: 100%;
}

// Remove a margin do card para que o fill-height-content possa preencher
// corretamente a altura do container
.fill-height-content astutus-card mat-card {
  margin-bottom: unset;
}