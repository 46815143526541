// Sobrescreve as variáveis padrão para forçar a
// animação do ripple ser renderizada no centro do elemento.
$mat-radio-size: 14px !default;
$mat-radio-ripple-radius: 25px;

mat-radio-group {

  .mat-radio-container, 
  .mat-radio-outer-circle, 
  .mat-radio-inner-circle {
    width: $mat-radio-size !important;
    height: $mat-radio-size !important;
  }
  
  .mat-radio-label-content {
    font-size: 14px !important;
  }

  .mat-radio-ripple {
    position: absolute;
    left: calc(50% - #{$mat-radio-ripple-radius});
    top: calc(50% - #{$mat-radio-ripple-radius});
    height: $mat-radio-ripple-radius * 2;
    width: $mat-radio-ripple-radius * 2;
    z-index: 1;
    pointer-events: none;
  }

  // Aumenta o espaçamento entre cada opção do radio-group
  .mat-radio-button {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
}