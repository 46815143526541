@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  position: relative !important;

  &::before {
    @extend .spinner-content;
  }

  ::before {
    @extend .spinner-content;
  }
}

.spinner-content {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #c6dedf;
  border-top-color: $nexus-cloud-accent;
  animation: spinner 0.8s linear infinite;
}
