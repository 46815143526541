mat-form-field.mat-mdc-form-field[nex-mdc-form-field],
mat-form-field.mat-mdc-form-field.nex-mdc-form-field {
  --mdc-filled-text-field-disabled-container-color: transparent;
  --mdc-filled-text-field-container-color: transparent;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-text-field-hover-active-indicator-color: #e5e5e5;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-disabled-label-text-color: rgb(0 0 0 / 54%);
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-text-line-height: 1.125;
  --mat-form-field-subscript-text-line-height: 17px;
  --mdc-filled-text-field-active-indicator-height: 0px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-size: 10.5px;

  &[nex-appearance='none'] .mdc-text-field--filled {
    --mdc-filled-text-field-active-indicator-height: 0;
    --mdc-filled-text-field-focus-active-indicator-height: 0;
  }

  margin-bottom: 8px;

  mat-hint {
    color: rgba(0, 0, 0, 0.6);
  }

  .mat-mdc-form-field-bottom-align {
    height: 0;
  }

  .mat-mdc-text-field-wrapper {
    padding: 0;

    .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 0.375em;
    }

    .mat-mdc-form-field-focus-overlay {
      background-color: transparent;
    }

    .mdc-floating-label:not(.mdc-floating-label--float-above) {
      transform: translateY(0);
    }

    &.mdc-text-field--filled {
      --mdc-filled-text-field-container-shape: 0;

      &.mdc-text-field--disabled .mdc-line-ripple::before {
        --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.12);

        background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.42) 0%,
          rgba(0, 0, 0, 0.42) 33%,
          transparent 0%
        );
        background-origin: border-box;
        background-size: 4px 100%;
        background-repeat: repeat-x;
      }
    }

    &:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
      min-height: unset;
      padding: 0.4375em 0;
      border-top: 0.84375em solid transparent;
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    margin-bottom: 1.667em;

    > * {
      padding-left: 0;
    }
  }

  .mat-mdc-form-field-bottom-align::before {
    margin-top: -0.155em;
  }

  .mat-mdc-form-field-icon-prefix {
    padding: 8px 4px 0 0;
  }

  .mat-mdc-form-field-icon-suffix {
    padding: 8px 0 0 4px;
  }

  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-icon-suffix {
    .mat-mdc-icon-button {
      --mdc-icon-button-icon-size: 24px;

      height: 24px;
      width: 24px;
      padding: 0;
    }
  }
}
