.form-container {
  position: relative;
  width: 100%;

  .form-inline {
    margin-bottom: 15px;

    .form-group {
      display: inline-block;
      margin-right: 20px
    }
  }
}