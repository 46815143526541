// Garante que um input respeite o tamanho máximo do seu elemento pai
.mat-form-field {
  max-width: 100%;
  margin-bottom: 8px;
}

// Aumenta o texto quando o float está ativo
.mat-form-field-placeholder.mat-form-field-float:not(.mat-form-field-empty),
.mat-focused .mat-form-field-placeholder.mat-form-field-float {
  transform: translateY(-1.28125em) scale(0.85) perspective(100px) translateZ(0.001px) !important;
}

// Afina a linha dos inputs
.mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-form-field-underline.mat-disabled {
  background-color: transparent !important;
}

// Define o tamanho do label do radio, checkbox e dos inputs
.mat-radio-button,
.mat-form-field,
.mat-checkbox {
  font-size: 14px !important;
}
