mat-slide-toggle {
  label {
    color: rgba(0, 0, 0, 0.54);
  }

  &.mat-checked {
    label {
      color: rgba(0, 0, 0, 0.87);
    }
  }
}