.card {
  border-radius: 2px;
  padding: 15px;
}

.card-content {
  font-size: 1.2rem;
  font-weight: bold;
}

.card-description {
  padding-left: 36px;
}

.card-currrency {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.currency-symbol {
  margin-right: 8px;
}

.currency-amount {
  font-size: 2.4rem;
}