.icon-default {
  color: $color-default;
}

.icon-danger {
  color: $color-danger;
}

.icon-warn {
  color: $color-warn;
}

.icon-success {
  color: $color-success;
}
