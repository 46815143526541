mat-expansion-panel {
  border-radius: 2px;

  &.mat-expanded mat-expansion-panel-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .mat-expansion-panel-body {
    padding-top: 15px;
  }

  .mat-expansion-panel-header-description {
    flex-grow: 1;
  }
}
